@import 'index.scss';

/* export SASS variables to be used in React components */
:export {
  colorLight: $light;
  colorPrimary: $primary;
  colorPrimary75: $primary-75;
  colorPrimary50: $primary-50;
  colorPrimary25: $primary-25;
  colorSecondary: $secondary;
  colorDanger: $danger;
  borderRadius: $border-radius;
}
