/* Bootstrap customizations */
$headings-font-weight: 800;
$font-family-base: 'Raleway', sans-serif;
$primary: #f55a0c;
$primary-75: #f55a0cc0;
$primary-50: #f55a0c80;
$primary-25: #f55a0c40;
$light: #fff1dbff;

/* End Bootstrap customizations */
@import '~bootstrap/scss/bootstrap';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
